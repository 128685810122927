


































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface PricesServices {
  title: string
  pictures: Picture[]
  items: {
    title: string
    htmltext: string
  }[]
}

import FlexibleGallery from '@/components/flexible/Gallery.vue'

export default defineComponent({
  name: 'PricesServices',
  components: {
    FlexibleGallery,
  },
  props: {
    content: {
      type: Object as PropType<PricesServices>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
