





































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

import UiPrices from '@/components/ui/Prices.vue'
import UiPicture from '@/components/ui/Picture.vue'

interface PricesExamples {
  headline: string
  title: string
  htmltext: string
  pictureLeft: Picture
  pictureRight: Picture
  price: {
    label: string
    example: string
  }
}

export default defineComponent({
  name: 'PricesExamples',
  components: { UiPicture, UiPrices },
  props: {
    content: {
      type: Object as PropType<PricesExamples>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
